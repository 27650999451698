import React from "react";
import kramed from "kramed";
import MiniCTA from "../forms/mini-cta";
import Link from "../misc/link";

const ComparisonTable = ({ competitor }) => {
    return (
        <section className="st-section">
            <div className="outline_wrap">
                <ul className="outline">
                    <Link to="#about">
                        <li>About</li>
                    </Link>
                    <Link to="#table">
                        <li>Features table</li>
                    </Link>
                    <Link to="#transformations">
                        <li>Transformations</li>
                    </Link>
                    <Link to="#data-src-dest">
                        <li>Data sources and destinations</li>
                    </Link>
                    <Link to="#support-doc-training">
                        <li>Support, documentation, and training</li>
                    </Link>
                    <Link to="#pricing">
                        <li>Pricing</li>
                    </Link>
                </ul>
            </div>
            <div className="comp_content" style={{ marginTop: 0 }}>
                <p>
                    Most businesses have data stored in a variety of locations, from in-house databases to SaaS
                    platforms. To get a full picture of their finances and operations, they pull data from all those
                    sources into a data warehouse or data lake and run analytics against it. But they don't want to
                    build and maintain their own data pipelines.
                </p>
                <p>
                    Fortunately, it’s not necessary to code everything in-house. Here's an comparison of two such tools,
                    head to head.
                </p>
                <div id="about">
                    <div className="about_competitor">
                        <h3>{`About ${competitor.name}`}</h3>
                        {competitor?.about?.about && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: kramed(competitor?.about?.about),
                                }}
                            />
                        )}
                    </div>
                    <h3>About Stitch</h3>
                    <p>
                        Stitch Data Loader is a cloud-based platform for ETL — extract, transform, and load. More than
                        3,000 companies use Stitch to move billions of records every day from SaaS applications and
                        databases into data warehouses and data lakes, where it can be analyzed with BI tools. Stitch is
                        a Talend company and is part of the Talend Data Fabric.
                    </p>
                </div>
            </div>
            <div className="comp_content">
                <table id="table" className="comp_table">
                    <tbody>
                        <tr>
                            <th aria-label="Separator" colSpan="3"></th>
                            <th colSpan="4">
                                <img
                                    className="company-logos"
                                    src={`${competitor.competitorLogo.file.url}`}
                                    alt={`${competitor.name} Logo`}
                                />
                            </th>
                            <th colSpan="4">
                                <img
                                    className="company-logos"
                                    src={`//images.ctfassets.net/k49d63tr8kcn/31QeLl04xrBkUBAcYn6W7C/b2a44e50c30450c02e53514a44e5352c/competitors_stitch.svg`}
                                    alt={`Stitch Logo`}
                                />
                            </th>
                        </tr>
                        <tr>
                            <td colSpan="3">Focus</td>
                            <td colSpan="4">{competitor.focus}</td>
                            <td colSpan="4">Data ingestion, ELT</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Database replication</td>
                            <td colSpan="4">{competitor.databaseReplication}</td>
                            <td colSpan="4">
                                Full table; incremental via change data capture or SELECT/replication keys
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">SaaS sources</td>
                            <td colSpan="4">{competitor.saasSources}</td>
                            <td colSpan="4">More than 100</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Ability for customers to add new data sources</td>
                            <td colSpan="4">{competitor.addNewDataSources}</td>
                            <td colSpan="4">Yes</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Connects to data warehouses? Data lakes?</td>
                            <td colSpan="4">{competitor.dataWarehousesDataLakes}</td>
                            <td colSpan="4">Yes / Yes</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Transparent pricing</td>
                            <td colSpan="4">{competitor.transparentPricing}</td>
                            <td colSpan="4">
                                <Link to="https://www.stitchdata.com/pricing/">Yes</Link>
                            </td>
                        </tr>
                        {competitor.g2_sat_score !== null && (
                            <tr>
                                <td colSpan="3">
                                    G2{" "}
                                    <Link to="https://www.g2.com/categories/etl-tools" target="_new">
                                        customer satisfaction
                                    </Link>
                                    <span id="tooltip1" className="g2-toolip">
                                        <img className="help" src="/images/icons/icon-help.svg" alt="Help" />
                                    </span>
                                </td>
                                <td colSpan="4">{competitor.g2_sat_score}/5</td>
                                <td colSpan="4">
                                    <Link to="https://www.g2.com/products/stitch-data-stitch/reviews">
                                        4.8/5 (+
                                        {Math.ceil(100 * ((4.8 - competitor.g2_sat_score) / 5))}%)
                                    </Link>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="3">Support SLAs</td>
                            <td colSpan="4">{competitor.supportSla}</td>
                            <td colSpan="4">Available</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Purchase process</td>
                            <td colSpan="4">{competitor.purchaseProcess}</td>
                            <td colSpan="4">
                                Options for self-service or talking with sales. Also available from{" "}
                                <Link
                                    to="https://aws.amazon.com/marketplace/pp/B07TW81CPH?qid=1562866381551&sr=0-3"
                                    target="_new"
                                >
                                    the AWS store
                                </Link>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">Compliance, governance, and security certifications</td>
                            <td colSpan="4">{competitor.complianceGovernanceAndSecurityCerts}</td>
                            <td colSpan="4">HIPAA, GDPR, SOC 2</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Data sharing</td>
                            <td colSpan="4">{competitor.dataSharing}</td>
                            <td colSpan="4">
                                Yes, through{" "}
                                <Link to="https://www.talend.com/products/data-fabric/">Talend Data Fabric</Link>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">Vendor lock-in</td>
                            <td colSpan="4">{competitor.vendorLockIn}</td>
                            <td colSpan="4">Month to month or annual contracts. Open source integrations</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Developer tools</td>
                            <td colSpan="4">{competitor.developerTools}</td>
                            <td colSpan="4">
                                Import API, Stitch Connect API for integrating Stitch with other platforms,{" "}
                                <Link to="http://www.singer.io">Singer</Link> open source project
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="comp_content_inner">
                    <p>Let's dive into some of the details of each platform.</p>
                    <div className="comp_info">
                        <div className="pricing">
                            <h2 id="transformations" className="st-heading--2">
                                Transformations
                            </h2>
                            <h4>{competitor.name}</h4>
                            <p>{competitor.transformations.transformations}</p>
                            <h4>Stitch</h4>
                            <p>
                                Stitch is an ELT product. Within the pipeline, Stitch does only transformations that are
                                required for compatibility with the destination, such as translating data types or
                                denesting data when relevant. Stitch is part of Talend, which also provides tools for
                                transforming data either within the data warehouse or via external processing engines
                                such as Spark and MapReduce. Transformations can be defined in SQL, Python, Java, or via
                                graphical user interface.
                            </p>
                        </div>
                    </div>
                    <MiniCTA
                        heading={`Try Stitch for free for 14 days`}
                        formtype={`cta-email-signup`}
                        formid={`3eec85da40e1`}
                    />
                    <div className="comp_info">
                        <div className="support-sources">
                            <h2 id="data-src-dest" className="st-heading--2">
                                Connectors: Data sources and destinations
                            </h2>
                            <p>Each of these tools supports a variety of data sources and destinations.</p>
                            <h4>{competitor.name}</h4>
                            <p>{competitor.dataSourcesAndDestinations.dataSourcesAndDestinations}</p>
                            <h4>Stitch</h4>
                            <p>
                                Stitch supports{" "}
                                <Link to="https://www.stitchdata.com/integrations/sources/">
                                    more than 100 database and SaaS integrations
                                </Link>
                                as data sources, and eight data warehouse and data lake destinations. Customers can
                                contract with Stitch to build new sources, and anyone can add a new source to Stitch by
                                developing it according to the standards laid out in{" "}
                                <Link to="https://www.singer.io/#what-it-is">Singer</Link>, an open source toolkit for
                                writing scripts that move data. Singer integrations can be run independently, regardless
                                of whether the user is a Stitch customer. Running Singer integrations on Stitch’s
                                platform allows users to take advantage of Stitch's monitoring, scheduling, credential
                                management, and autoscaling features.
                            </p>
                        </div>
                    </div>
                    <div className="comp_info">
                        <div className="support-sources">
                            <h2 id="support-doc-training" className="st-heading--2">
                                Support, documentation, and training
                            </h2>
                            <p>
                                Data integration tools can be complex, so vendors offer several ways to help their
                                customers. Online documentation is the first resource users often turn to, and support
                                teams can answer questions that aren't covered in the docs. Vendors of the more
                                complicated tools may also offer training services.
                            </p>
                            <h4>{competitor.name}</h4>
                            <p>{competitor.supportDocumentationAndTraining.supportDocumentationAndTraining}</p>
                            <h4>Stitch</h4>
                            <p>
                                Stitch provides in-app chat support to all customers, and phone support is available for
                                Enterprise customers. Support SLAs are available. Documentation is comprehensive and is
                                open source —{" "}
                                <Link to="https://github.com/stitchdata/docs#stitch-documentation">
                                    anyone can contribute
                                </Link>{" "}
                                additions and improvements or repurpose the content. Stitch does not provide training
                                services.
                            </p>
                        </div>
                    </div>
                    <div className="comp_info">
                        <div className="support-sources">
                            <h2 id="pricing" className="st-heading--2">
                                Pricing
                            </h2>
                            <h4>{competitor.name}</h4>
                            <p>{competitor.pricing.pricing}</p>
                            <h4>Stitch</h4>
                            <p>
                                Stitch has <Link to="https://www.stitchdata.com/pricing/">pricing</Link> that scales to
                                fit a wide range of budgets and company sizes. All new users get an unlimited 14-day
                                trial. Standard plans range from $100 to $1,250 per month depending on scale, with
                                discounts for paying annually. Enterprise plans for larger organizations and
                                mission-critical use cases can include custom features, data volumes, and service
                                levels, and are priced individually.
                            </p>
                        </div>
                    </div>
                    <div className="comp_info">
                        <div className="get-started">
                            <h2 className="st-heading--2">Get started now</h2>
                            <p style={{ marginBottom: 0 }}>
                                Which tool is better overall? That's something every organization has to decide based on
                                its unique requirements, but we can help you get started.{" "}
                                <Link to="https://www.stitchdata.com/signup">Sign up now</Link> for a free trial of
                                Stitch.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ComparisonTable;
