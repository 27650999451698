import React from "react";
import { graphql } from "gatsby";
import ComparisonTable from "../components/competitor/comparison-table";
import CTASection from "../components/misc/cta-section";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";

const Competitor = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const competitor = data.contentfulCompetitor;
    const bodyClasses = `${pageContext.type + "s"} vs vs_${competitor.slug}`;

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            pageHeading={`${competitor.name} vs. Stitch`}
            pageSubHeading={`ETL software comparison`}
        >
            <Seo
                type={"Page"}
                title={`${competitor.name} vs. Stitch - Compare features, pricing, services, and more.`}
                description={`${competitor.name} and Stitch are both popular ETL tools for data ingestion into cloud data warehouses. This quick guide helps you compare features, pricing, and services.`}
                keywords={[
                    `${competitor.name}`,
                    `Stitch`,
                    `product`,
                    `comparison`,
                    `ETL`,
                    `ELT`,
                    `data ingestion`,
                    `data warehouse`,
                ]}
                slug={"/vs/" + competitor.slug}
                bodyClass={bodyClasses}
            />
            <ComparisonTable competitor={competitor} />
            <CTASection
                heading={`Give Stitch a try, on us`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight st-section--no-margin`}
            />
        </Layout>
    );
};

export default Competitor;

export const query = graphql`
    query($slug: String!) {
        contentfulCompetitor(slug: { eq: $slug }) {
            id
            name
            slug
            dataWarehousesDataLakes
            databaseReplication
            developerTools
            focus
            g2_sat_score
            addNewDataSources
            complianceGovernanceAndSecurityCerts
            dataSharing
            purchaseProcess
            saasSources
            supportSla
            transparentPricing
            vendorLockIn
            about {
                about
            }
            competitorLogo {
                file {
                    url
                }
            }
            dataSourcesAndDestinations {
                dataSourcesAndDestinations
            }
            supportDocumentationAndTraining {
                supportDocumentationAndTraining
            }
            transformations {
                transformations
            }
            pricing {
                pricing
            }
        }
    }
`;
